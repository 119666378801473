import React, { useEffect, useState } from "react";
import "../css/news.css";

const DataFetcherNews = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const handleRight = (e) => {
    e.preventDefault();
    const element = document.getElementById("wrapper-noticias");
    element.scrollLeft += 300;
  };

  const handleLeft = (e) => {
    e.preventDefault();
    const element = document.getElementById("wrapper-noticias");
    element.scrollLeft -= 300;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://noticias.utpl.edu.ec/parque/servicio-parque"
        ); // Cambia esta URL por la API que desees consumir
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const jsonData = await response.json();
        setData(jsonData);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []); // El array vacío significa que este efecto solo se ejecutará una vez al montar el componente

  if (loading) {
    return <div>Cargando...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <center>
        <h2 className="width500">
          Descubre nuestras <b>últimas noticias</b>
        </h2>

        <div className="gallery-indicators-noticias">
          <span className="material-icons" onClick={handleLeft}>
            arrow_back
          </span>
          <span className="material-icons" onClick={handleRight}>
            arrow_forward
          </span>
        </div>
      </center>
      <div className="wrapper-noticias layout9" id="wrapper-noticias">
        <div className="flex-noticias  padding2">
          {data.map((item, index) => (
            <>
              <div key={index}>
                {item.field_multimedia.length > 1 ? (
                  <>
                    <div className="noticias-imagen">
                      <img src={item.field_multimedia}></img>
                    </div>
                  </>
                ) : (
                  <div
                    className="noticias-imagen"
                    dangerouslySetInnerHTML={{ __html: item.field_imagen }}
                  />
                )}

                <div className="layout9">
                  <p className="title">
                    <b>{item.title}</b>
                  </p>
                  <div dangerouslySetInnerHTML={{ __html: item.Enlace }} />
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DataFetcherNews;
