import Breadcumbs from "../components/Breadcumbs";
import { NavLink } from "react-router-dom";
import ModalFixed from "../components/ModalFixed";

import carta_menor from "../docs/carta_menor.pdf";
import carta_mayor from "../docs/carta_mayor.pdf";

function PruebaAdmision() {
  return (
    <>
      {" "}
      <div className="margin">
        <Breadcumbs
          content={
            <>
              <NavLink to="/presencial">Modalidad Presencial</NavLink>
              <span class="material-icons">chevron_right</span>
              <NavLink to="/presencial/prueba">Prueba de admisión</NavLink>
            </>
          }
        ></Breadcumbs>
      </div>
      <div className="relative">
        <div className="layout8">
          <center>
            <h2>
              <b>Prueba de admisión</b>
            </h2>
            <h3>
              Modalidad <b>presencial</b>
            </h3>
            <p>
              <b>Periodo octubre 2024 - febrero 2025</b>
            </p>
            <p>
              <b>Convocatoria extraordinaria</b>
            </p>
          </center>
        </div>
        <ModalFixed
          color="#6d46ce"
          id="tematicas"
          title="Temáticas prueba 📘"
          content={
            <>
              <img
                src="https://images.ctfassets.net/112w21q1rvqr/2A6cxv6QvOauK3XhNbUGpS/dba1cad40ead73f348dbb6ec6884a500/tamaticas__1___1_.png"
                alt="utpl"
              ></img>
            </>
          }
        ></ModalFixed>
      </div>
      <div className="  padding">
        <div className="layout8">
          <ul>
            <li>
              <a
                target="_blank"
                href={carta_mayor}
              >
                Carta términos y condiciones mayores de edad
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href={carta_menor}
              >
                Carta términos y condiciones menores de edad
              </a>
            </li>
          </ul>
          <div className="margin">
        

            <iframe
              title="Consulta de horarios"
              width="100%"
              height="500"
              src="https://app.powerbi.com/view?r=eyJrIjoiNjc5MWM0Y2ItMWI2Yy00Zjc0LWEyOGYtZGYyZjgyNjZlZTQ4IiwidCI6IjZlZWI0OWFhLTQzNmQtNDNlNi1iZWNkLWJiZGY3OWU1MDc3ZCIsImMiOjR9"
              frameborder="0"
              allowFullScreen="true"
            ></iframe>
          </div>
        </div>
      </div>
    </>
  );
}

export default PruebaAdmision;
