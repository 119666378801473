import { NavLink } from "react-router-dom";
import Breadcumbs from "../../components/Breadcumbs";
import useContentful from "../../hooks/use-contenful";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import BlockRightImage from "../../components/BlockRightImage";
import Accordion from "../../components/Accordion";
import Notification from "../../components/Notification";
import Block4Columns from "../../components/Block4ColumnsText";
import ModalFixed from "../../components/ModalFixed";
import Modal from "../../components/Modal";
import Gallery from "../../components/Gallery";
import BlockLeftImage from "../../components/BlockLeftImage";
import BlockImageCabecera from "../../components/BlockImageCabecera";

const query = `
query 
{
    videoTemplate(id: "7jgpMsCmEsOho6PZCz96YW") {
    image{url, title}
      contentBlock1{json}
       contentBlock2{json}
           imageBlock2{url, title}
    contentBlock3{json}
    contentBlock4{json}
    imageBlock4{url, title}
    contentBlock5{json}
    imageBlock5{url, title}
    contentBlock6{json}
  imageBlock7{url, title}
    contentBlock7{json}
      imageBlock8{url, title}
    contentBlock8{json}
    contentBlock9{json}
    contentBlock10{json}
      imageBlock13{url, title}
      contentBlock13{json}

       galleryCollection{
        items{url title}
         }     
        gallery2Collection{
        items{url title}
        }
         gallery3Collection{
        items{url title}
        }
   
    }
  
}
`;

function Compentencias() {
  let { data, errors } = useContentful(query);
  if (errors)
    return <span>{errors.map((error) => error.message).join(",")}</span>;
  if (!data) return <p>Loading...</p>;

  return (
    <>
      <div className="margin">
        <BlockImageCabecera
          src={data.videoTemplate.image.url}
          title={documentToReactComponents(
            data.videoTemplate.contentBlock1.json
          )}
        ></BlockImageCabecera>
      </div>

      <div className="graybg">
        <div className="relative">
          <Notification
            content={
              <>
                {documentToReactComponents(
                  data.videoTemplate.contentBlock6.json
                )}
              </>
            }
          ></Notification>
          <ModalFixed
            title="Proceso de inscripción 👍"
            color="#37994f"
            content={
              <>
                <Gallery
                  gallery={data.videoTemplate.galleryCollection.items}
                  width="400"
                ></Gallery>
              </>
            }
          ></ModalFixed>
        </div>
        <Breadcumbs
          content={
            <>
              <NavLink to="/competencias">Competencias Específicas</NavLink>
            </>
          }
        ></Breadcumbs>

        <div className="icon70 padding">
          {" "}
          <Block4Columns
            col1={
              <>
                <img src={data.videoTemplate.imageBlock2.url}></img>

                {documentToReactComponents(
                  data.videoTemplate.contentBlock2.json
                )}
                <Accordion
                  title="Excepciones de asignaturas"
                  content={
                    <>
                      {documentToReactComponents(
                        data.videoTemplate.contentBlock3.json
                      )}
                    </>
                  }
                ></Accordion>
              </>
            }
            col2={
              <>
                <img src={data.videoTemplate.imageBlock4.url}></img>

                {documentToReactComponents(
                  data.videoTemplate.contentBlock4.json
                )}
              </>
            }
            col3={
              <>
                <img src={data.videoTemplate.imageBlock5.url}></img>

                {documentToReactComponents(
                  data.videoTemplate.contentBlock5.json
                )}
              </>
            }
            col4={
              <>
                <img src={data.videoTemplate.imageBlock13.url}></img>
                <div className="nobutton">
                  {documentToReactComponents(
                    data.videoTemplate.contentBlock13.json
                  )}
                </div>
              </>
            }
          ></Block4Columns>
        </div>
      </div>

      <div className="relative gray1bg">
        <div>
          <BlockRightImage
            src={data.videoTemplate.imageBlock7.url}
            content={
              <>
                {documentToReactComponents(
                  data.videoTemplate.contentBlock7.json
                )}
              </>
            }
          ></BlockRightImage>
        </div>

        <ModalFixed
          title="Proceso de legalización 📝"
          color="#5854a7"
          content={
            <>
              <Gallery
                gallery={data.videoTemplate.gallery2Collection.items}
                width="400"
              ></Gallery>
            </>
          }
        ></ModalFixed>
      </div>
      <div className="relative">
        <div>
          {" "}
          <BlockLeftImage
            src={data.videoTemplate.imageBlock8.url}
            content={
              <>
                {documentToReactComponents(
                  data.videoTemplate.contentBlock8.json
                )}
                <Accordion
                  title="¿Aprobaste el curso antes del periodo octubre 2023- febrero 2024?"
                  content={
                    <>
                      <div className="button-content">
                        {documentToReactComponents(
                          data.videoTemplate.contentBlock9.json
                        )}
                      </div>
                      <Modal
                        title="Proceso de homologación ✍🏼️"
                        color="#d67d3a"
                        content={
                          <>
                            <Gallery
                              gallery={
                                data.videoTemplate.gallery3Collection.items
                              }
                              width="400"
                            ></Gallery>
                          </>
                        }
                      ></Modal>

                      {documentToReactComponents(
                        data.videoTemplate.contentBlock10.json
                      )}
                    </>
                  }
                ></Accordion>
                <br></br> <br></br>
              </>
            }
          ></BlockLeftImage>
        </div>

        <ModalFixed
          title="Proceso de legalización 📝"
          color="#5854a7"
          content={
            <>
              <Gallery
                gallery={data.videoTemplate.gallery2Collection.items}
                width="400"
              ></Gallery>
            </>
          }
        ></ModalFixed>
      </div>
    </>
  );
}

export default Compentencias;
