import BlockLeftImage from "./BlockLeftImage";
import Accordion from "./Accordion";

import "../css/blockpagos.css";
import BlockRightImage from "./BlockRightImage";

function PosgradoPagos() {
  return (
    <>
      <div className="icon350" id="descuentos">

    
        <BlockLeftImage
          src="https://utpl.edu.ec/recursos/img/costos.gif"
          content={
            <>
            {/* 
              <h3>
                ¡Aprovecha nuestros <b>descuentos por forma de pago! </b>
              </h3>
              <p>
                Paga al contado, con tarjeta de crédito o débito y obtén un
                <b> 12% de descuento al pagar la totalidad del programa.</b>
              </p>

              <p>
                Obtén un <b>8% de descuento al pagar semestralmente.</b>
              </p>
              <Accordion
                title="Otros descuentos"
                content={
                  <>
                    <ul>
                      <li>
                        <p>
                          Benefíciate de un 10% de descuento si perteneces a la
                          comunidad UTPL Alumni.{" "}
                        </p>
                      </li>
                    </ul>
                  </>
                }
              ></Accordion>
              <Accordion
                title="Plan de pagos"
                content={
                  <>
                    <p>
                      <b>Plan de pagos:</b>
                    </p>
                    <p>
                      <b>
                        Si optas por un plan de pagos, no obtendrás el descuento
                        por forma de pago.
                      </b>
                    </p>
                    <ul>
                      <li>
                        <p>
                          Paga 50% al momento de la matrícula y 50% a 60 días
                          plazo.
                        </p>
                      </li>
                      <li>
                        <p>
                          Paga 40% al momento de la matrícula, 30% hasta el 15
                          de noviembre de 2024 y 30% hasta el 30 de diciembre de
                          2024.
                        </p>
                      </li>
                    </ul>
                    <p></p>
                  </>
                }
              ></Accordion>*/}
                 <div className="padding2">
                <h3>
                  <b>Opciones</b> de pago
                </h3>
                <p>
                  Ofrecemos a nuestros estudiantes la opción de pagar matrícula
                  y aranceles en cuotas mensuales sin intereses, además de
                  convenios con instituciones financieras que ofrecen descuentos
                  especiales y financiamiento personalizado.
                </p>

                <a
                  className="button"
                  target="_blank"
                  href="https://utpl.edu.ec/documentos/opcionespago.pdf"
                >
                  Descubre nuestras opciones aquí{" "}
                </a>
              </div>{" "}
            </>
          }
        ></BlockLeftImage>
      </div>
      <div className="icon400" id="becas">
        <BlockRightImage
          src="https://www.utpl.edu.ec/recursos/img/becas-ser-mas.png"
          content={
            <>
              <h3>
                ¡Aprovecha ahora y <b>obtén hasta un 10% de descuento </b>en tu
                matrícula postulando una beca convenio!{" "}
              </h3>
              <a target="_blank" href="https://becas.utpl.edu.ec/convenio">
                Conoce los requisitos
              </a>
              <a target="_blank" href="https://becas.utpl.edu.ec">
                Postula a una beca
              </a>

              <Accordion
                title="Consulta las diversas opciones de becas que ofrecemos"
                content={
                  <>
                    <ul>
                      <li>
                        <p>
                          <a target="_blank" href="https://becas.utpl.edu.ec/desempleo">
                            Becas para personas en situación de desempleo.
                          </a>
                        </p>
                      </li>
                      <li>
                        <p>
                          <a target="_blank" href="https://becas.utpl.edu.ec/discapacidad">
                            Becas para personas con discapacidad.
                          </a>
                        </p>
                      </li>
                      <li>
                        <p>
                          <a target="_blank" href="https://becas.utpl.edu.ec/familiar">
                            Becas destinadas a familiares de la comunidad UTPL.
                          </a>
                        </p>
                      </li>
                      <li>
                        <p>
                          <a target="_blank" href="https://becas.utpl.edu.ec/nivel-de-ingresos">
                            Becas basadas en nivel de ingresos.
                          </a>
                        </p>
                      </li>
                      <li>
                        <p>
                          <a target="_blank" href="https://becas.utpl.edu.ec/pueblos-nacionalidades">
                            Becas para comunidades indígenas y pueblos del
                            Ecuador.
                          </a>
                        </p>
                      </li>
                      <li>
                        <p>
                          <a target="_blank" href="https://becas.utpl.edu.ec/religiosos">
                            Becas religiosas.
                          </a>
                        </p>
                      </li>
                    </ul>
                  </>
                }
              ></Accordion>
              <br></br>
              <p>
                Postula a becas y accede a descuentos hasta el 25%.
              </p>
            </>
          }
        ></BlockRightImage>
      </div>
    </>
  );
}

export default PosgradoPagos;
