import BlockLeftImage from "./BlockLeftImage";
import Accordion from "./Accordion";

import "../css/blockpagos.css";
import BlockRightImage from "./BlockRightImage";

function Enlineapagos() {
  return (
    <>
      <div className="icon350" id="descuentos">
        <BlockLeftImage
          src="https://utpl.edu.ec/recursos/img/costos.gif"
          content={
            <>

            {/*
              <h3>
                ¡Aprovecha nuestros <b>descuentos por forma de pago! </b>
              </h3>
              <p>
                Si has sido admitido en una de nuestras carreras, descubre los
                beneficios que ofrecemos según tu forma de pago.
              </p>
              <h4>
                Paga al contado, con tarjeta de crédito o débito y{" "}
                <b>obtén un 8% de descuento.</b>
              </h4>

              <Accordion
                title="Otros descuentos"
                content={
                  <>
                    <ul>
                      <li>
                        <p>
                          Benefíciate de un 10% de descuento si perteneces a la
                          comunidad UTPL Alumni.{" "}
                        </p>
                      </li>

                      <li>
                        <p>
                          Obtén 50% de descuento por reingreso por una sola
                          ocasión, no se combina con becas ni descuentos
                          iguales o menores.
                        </p>
                      </li>
                    </ul>
                  </>
                }
              ></Accordion>
              <Accordion
                title="Plan de pagos"
                content={
                  <>
                    <p>
                      <b>Plan de pagos:</b>
                    </p>
                    <p>
                      <b>
                        Si optas por un plan de pagos, no obtendrás el descuento
                        por forma de pago.
                      </b>
                    </p>
                    <ul>
                      <li>
                        <p>
                          Paga 50% al momento de la matrícula y 50% hasta el 30
                          de noviembre de 2024
                        </p>
                      </li>
                      <li>
                        <p>
                          Paga 40% al momento de la matrícula y 30% hasta el 15
                          de noviembre de 2024 y 30% hasta el 30 de diciembre de
                          2024
                        </p>
                      </li>
                    </ul>
                    <p>
                      <b>Internados rotativos:</b>
                    </p>

                    <ul>
                      <li>
                        <p>
                          Paga 50% al momento de la matrícula y 50% a 180 días
                          plazo
                        </p>
                      </li>
                      <li>
                        <p>
                          Paga 25% al momento de la matrícula, 25% a 60 días,
                          25% a 120 días y 25% a 180 días
                        </p>
                      </li>
                    </ul>
                  </>
                }
              ></Accordion> */}
                 <div className="padding2">
                <h3>
                  <b>Opciones</b> de pago
                </h3>
                <p>
                  Ofrecemos a nuestros estudiantes la opción de pagar matrícula
                  y aranceles en cuotas mensuales sin intereses, además de
                  convenios con instituciones financieras que ofrecen descuentos
                  especiales y financiamiento personalizado.
                </p>

                <a
                  className="button"
                  target="_blank"
                  href="https://utpl.edu.ec/documentos/opcionespago.pdf"
                >
                  Descubre nuestras opciones aquí{" "}
                </a>
              </div>{" "}
            </>
          }
        ></BlockLeftImage>
      </div>
      <div className="icon400" id="becas">
        <BlockRightImage
          src="https://www.utpl.edu.ec/recursos/img/becas-ser-mas.png"
          content={
            <>
              <h3>
                Si confirmaste tu cupo postula a una de nuestras{" "}
                <b>becas Ser Más</b>
              </h3>

              <a target="_blank" href="https://becas.utpl.edu.ec">
                Postula ahora
              </a>

              <Accordion
                title="Consulta las diversas opciones de becas que ofrecemos"
                content={
                  <>
                    <ul>
                      <li>
                        <p>
                          <a
                            target="_blank"
                            href="https://becas.utpl.edu.ec/abanderados-y-mejores-egresados"
                          >
                            Becas para abanderados y mejores egresados.
                          </a>
                        </p>
                      </li>
                      <li>
                        <p>
                          <a
                            target="_blank"
                            href="https://becas.utpl.edu.ec/club-universitario"
                          >
                            Becas para estudiantes que pertenezcan a clubes
                            universitarios y grupos de arte.
                          </a>
                        </p>
                      </li>
                      <li>
                        <p>
                          <a
                            target="_blank"
                            href="https://becas.utpl.edu.ec/deportistas-destacados"
                          >
                            Becas para deportistas destacados.
                          </a>
                        </p>
                      </li>
                      <li>
                        <p>
                          <a
                            target="_blank"
                            href="https://becas.utpl.edu.ec/discapacidad"
                          >
                            Becas para personas con discapacidad.
                          </a>
                        </p>
                      </li>
                      <li>
                        <p>
                          <a
                            target="_blank"
                            href="https://becas.utpl.edu.ec/excelencia-acad%C3%A9mica"
                          >
                            Becas por excelencia académica.
                          </a>
                        </p>
                      </li>
                      <li>
                        <p>
                          <a
                            target="_blank"
                            href="https://becas.utpl.edu.ec/familiar"
                          >
                            Becas destinadas a familiares de la comunidad UTPL.
                          </a>
                        </p>
                      </li>
                      <li>
                        <p>
                          <a
                            target="_blank"
                            href="https://becas.utpl.edu.ec/nivel-de-ingresos"
                          >
                            Becas basadas en nivel de ingresos.
                          </a>{" "}
                        </p>
                      </li>
                      <li>
                        <p>
                          <a
                            target="_blank"
                            href="https://becas.utpl.edu.ec/pueblos-nacionalidades"
                          >
                            Becas para comunidades indígenas y pueblos del
                            Ecuador.
                          </a>
                        </p>
                      </li>
                      <li>
                        <p>
                          <a
                            target="_blank"
                            href="https://becas.utpl.edu.ec/religiosos"
                          >
                            Becas religiosas.
                          </a>
                        </p>
                      </li>
                      <li>
                        <p>
                          <a target="_blank" href="https://becas.utpl.edu.ec">
                            Conoce aquí más opciones.
                          </a>
                        </p>
                      </li>
                    </ul>
                  </>
                }
              ></Accordion>
              <br></br>
              <p>
                Postula a becas y accede a descuentos hasta el 40%. Los
                descuentos por forma de pago no aplican a becas mayores al 40%.
              </p>
            </>
          }
        ></BlockRightImage>
      </div>
    </>
  );
}

export default Enlineapagos;
