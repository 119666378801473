import "../css/carousel.css";

const Carousel = (props) => {
  const handleRight = (e) => {
    e.preventDefault();
    const element = document.getElementById(props.id);
    element.scrollLeft += 300;
  };

  const handleLeft = (e) => {
    e.preventDefault();
    const element = document.getElementById(props.id);
    element.scrollLeft -= 300;
  };

  return (
    <div>
      <center>
        <div className="gallery-indicators-carousel">
          <span className="material-icons" onClick={handleLeft}>
            arrow_back
          </span>
          <span className="material-icons" onClick={handleRight}>
            arrow_forward
          </span>
        </div>
      </center>
      <div className="wrapper-carousel layout9" id={props.id}>
        <div >{props.content}</div>
      </div>
    </div>
  );
};

export default Carousel;
