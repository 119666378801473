import "../css/floating.css";
import asesoria from "../img/social/asesoria.png";
import escribenos from "../img/social/escribenos.png";
import { motion } from "framer-motion";
function Floating() {
  return (
    <>
      {" "}
      <motion.div
        className="wrapper-floating"
        initial={{ x: -60, opacity: 0 }}
        whileInView={{
          opacity: 1,
          transition: {
            delay: 0.4,
          },
        }}
        whileHover={{ x: 0 }}
      >
        <a href="https://wa.me/593999565400" target="_blank" rel="noreferrer">
          <img src={escribenos} alt="utpl"></img>
        </a>
      </motion.div>
      <motion.div
        className="wrapper-floating-2"
        initial={{ opacity: 0, x: -60 }}
        whileInView={{
          opacity: 1,
          transition: {
            delay: 0.1,
          },
        }}
        whileHover={{ x: 0 }}
      >
        <a href="https://utpl.edu.ec/zoom" target="_blank" rel="noreferrer">
          <img src={asesoria} alt="utpl"></img>
        </a>
      </motion.div>
    </>
  );
}

export default Floating;
