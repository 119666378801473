import { NavLink } from "react-router-dom";
import Breadcumbs from "../../components/Breadcumbs";
import useContentful from "../../hooks/use-contenful";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { motion } from "framer-motion";
import BlockRightImage from "../../components/BlockRightImage";
import Accordion from "../../components/Accordion";
import Notification from "../../components/Notification";
import Block4Columns from "../../components/Block4ColumnsText";
import ModalFixed from "../../components/ModalFixed";
import Modal from "../../components/Modal";
import Gallery from "../../components/Gallery";
import BlockLeftImage from "../../components/BlockLeftImage";
import BlockImageCabecera from "../../components/BlockImageCabecera";

const query = `
query 
{
    videoTemplate(id: "5Mq3o4JnDWYUJnz8KscUQ3") {
    image{url, title}
      contentBlock1{json}
      contentBlock2{json}
      contentBlock3{json}
      contentBlock4{json}
       imageBlock5{url, title}
      contentBlock5{json} 
        imageBlock6{url, title}   
      contentBlock6{json}    
        imageBlock7{url, title}   
      contentBlock7{json}  
        imageBlock8{url, title}   
      contentBlock8{json} 
       contentBlock9{json} 
        contentBlock10{json} 
         contentBlock11{json} 
}
}
`;

function SegundaLengua() {
  let { data, errors } = useContentful(query);
  if (errors)
    return <span>{errors.map((error) => error.message).join(",")}</span>;
  if (!data) return <p>Loading...</p>;

  return (
    <>
      <div className="margin">
        <BlockImageCabecera
          src={data.videoTemplate.image.url}
          title={documentToReactComponents(
            data.videoTemplate.contentBlock1.json
          )}
        ></BlockImageCabecera>
      </div>
      <div className="graybg">
        <Breadcumbs
          content={
            <>
              <NavLink to="/segunda-lengua">Segunda Lengua</NavLink>
            </>
          }
        ></Breadcumbs>

        <center>
          <div className="width500">
            {documentToReactComponents(data.videoTemplate.contentBlock2.json)}
          </div>
        </center>

        <Block4Columns
          col1={
            <a href="#examen">
              {documentToReactComponents(data.videoTemplate.contentBlock3.json)}
              <motion.div
                initial={{ y: 10 }}
                animate={{ y: 0, opacity: 0.8 }}
                transition={{ type: "spring", repeat: Infinity }}
              >
                <span className="material-icons">keyboard_arrow_down</span>
              </motion.div>
            </a>
          }
          col2={
            <a href="#certificados">
              {documentToReactComponents(
                data.videoTemplate.contentBlock11.json
              )}
              <motion.div
                initial={{ y: 10 }}
                animate={{ y: 0, opacity: 0.8 }}
                transition={{ type: "spring", repeat: Infinity }}
              >
                <span className="material-icons">keyboard_arrow_down</span>
              </motion.div>
            </a>
          }
        ></Block4Columns>
      </div>

      <div id="examen" className="padding relative">
        <div className="layout8 block-column">
          <div className="content wrapper-titleoffer">
            <center>
              {documentToReactComponents(data.videoTemplate.contentBlock4.json)}{" "}
            </center>
          </div>
        </div>

        <div className="icon70  relative">
          <div>
            {" "}
            <Block4Columns
              col1={
                <>
                  <img
                    src={data.videoTemplate.imageBlock5.url}
                    alt="utpl"
                  ></img>
                  {documentToReactComponents(
                    data.videoTemplate.contentBlock5.json
                  )}
                </>
              }
              col2={
                <>
                  <img
                    src={data.videoTemplate.imageBlock6.url}
                    alt="utpl"
                  ></img>
                  {documentToReactComponents(
                    data.videoTemplate.contentBlock6.json
                  )}
                </>
              }
              col3={
                <>
                  <img
                    src={data.videoTemplate.imageBlock7.url}
                    alt="utpl"
                  ></img>
                  {documentToReactComponents(
                    data.videoTemplate.contentBlock7.json
                  )}
                </>
              }
              col4={
                <>
                  <img
                    src={data.videoTemplate.imageBlock8.url}
                    alt="utpl"
                  ></img>
                  {documentToReactComponents(
                    data.videoTemplate.contentBlock8.json
                  )}
                </>
              }
            ></Block4Columns>
          </div>
          <ModalFixed
            color="#4e975b"
            title="Proceso de inscripción"
            content={<></>}
          ></ModalFixed>{" "}
        </div>
        <div className="block-column  relative">
          <div className="content layout8 ">
            <center>
              {" "}
              {documentToReactComponents(
                data.videoTemplate.contentBlock9.json
              )}{" "}
            </center>
            <div className="width700">
              <Accordion
                title="Calendario académico"
                content={documentToReactComponents(
                  data.videoTemplate.contentBlock10.json
                )}
              ></Accordion>
            </div>
          </div>
          <div>
            <ModalFixed
              color="#4e975b"
              title="Proceso de reconocimiento"
              content={<></>}
            ></ModalFixed>
          </div>
        </div>
      </div>
    </>
  );
}

export default SegundaLengua;
