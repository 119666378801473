import BannerBecasPagos from "../components/BannerBecasPagos";
import BannerGeneral from "../components/BannerGeneral";
import BlockBackgroundImage from "../components/BlockBackgroundImage";
import BlockImageCabecera from "../components/BlockImageCabecera";
import useContentful from "../hooks/use-contenful";
import Notification from "../components/Notification";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Breadcumbs from "../components/Breadcumbs";
import { NavLink } from "react-router-dom";
import ConectUs from "../components/ConectUs";
import ModalFixed from "../components/ModalFixed";
import PresencialOffer from "../components/PresencialOffer";
import PresencialPagos from "../components/PresencialPagos";
import { PresencialInscripcionFixed } from "../components/Pasos";


const query = `
query 
{
    videoTemplate(id: "7cnwxQRK0Yg9weoT5lPLNq") {
      imageBlock1{url}
      imageBlock5{url, title}
      contentBlock1{json}
      contentBlock2{json}
      contentBlock3{json}
      contentBlock4{json}
      imageBlock3{url}
      galleryCollection{
        items{url title}
         }

         gallery2Collection{
          items{url title}
           }
           gallery3Collection{
            items{url title}
             }
    }
}
`;
function Presencial() {
  let { data, errors } = useContentful(query);
  if (errors)
    return <span>{errors.map((error) => error.message).join(",")}</span>;
  if (!data) return <p>Loading...</p>;

  return (
    <>
      <div className="margin">
        <BlockImageCabecera
          src={data.videoTemplate.imageBlock1.url}
          title={
            <>
              {documentToReactComponents(data.videoTemplate.contentBlock1.json)}
              <div className="margin">
                <a className="button1" href="#descuentos">
                  <span class="material-icons">chevron_right</span> Accede a
                  descuentos
                </a>
                <a className="button1" href="#becas">
                  <span class="material-icons">chevron_right</span> Postula a
                  una beca
                </a>
              </div>
            </>
          }
        ></BlockImageCabecera>
      </div>
      <div className="relative">

        <div>
        <Notification
          content={
            <>
              {documentToReactComponents(data.videoTemplate.contentBlock2.json)}
            </>
          }
          link="https://prapps.utpl.edu.ec/ords/utpl_crm/r/registro-utpl/login"
          name_link="Inscríbete ahora"
        ></Notification>

        <PresencialInscripcionFixed></PresencialInscripcionFixed>
        </div>
        <div className="tematicas-prueba">
          <ModalFixed
            color="#6d46ce"
            id="tematicas"
            title="Temáticas prueba 📘"
            content={
              <>
                <img
                  src={data.videoTemplate.imageBlock5.url}
                  alt={data.videoTemplate.imageBlock5.title}
                ></img>
              </>
            }
          ></ModalFixed>
        </div>
      </div>

      <Breadcumbs
        content={
          <>
            <NavLink to="/presencial">Modalidad presencial</NavLink>
          </>
        }
      ></Breadcumbs>

      <PresencialOffer
        title={
          <>
            {documentToReactComponents(data.videoTemplate.contentBlock4.json)}
          </>
        }
        subtitle="Elige tu carrera"
      ></PresencialOffer>

      <PresencialPagos></PresencialPagos>
      <ConectUs
        lema="Descubre contenido exclusivo, mantente al tanto de las últimas novedades y únete a nuestra comunidad de estudiantes presenciales."
        facebook="https://www.facebook.com/utplpresencial/"
        instagram="https://www.instagram.com/utpl/"
        tiktok="https://www.tiktok.com/@utpl.ec"
      ></ConectUs>
      <BlockBackgroundImage
        src={data.videoTemplate.imageBlock3.url}
        content={documentToReactComponents(
          data.videoTemplate.contentBlock3.json
        )}
      ></BlockBackgroundImage>

   
      <BannerGeneral></BannerGeneral>

    </>
  );
}

export default Presencial;
